import React from 'react'
import Button from '../components/Button/Button';
import RichHood from '../assets/image/richhood.png';
import Signature from '../assets/image/Signature.png';
import Sliders from '../components/Slider/Sliders';
import BannerInner from '../components/BannerInner/BannerInner';

function History() {
  const links = ['Home', 'About'];
  return (
    <>
    <BannerInner pageName="History" links={links} />
    <section className='md:py-24 sm:py-20 py-10'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-end'>
          <div>
            <p className='text-red font-extrabold text-xl leading-6 mb-5'>OUR STORY</p>
            <h2 className='uppercase md:leading-[70px] leading-[normal] text-blue md:text-[50px] sm:text-[40px] text-[30px] font-medium'>Committed to your <br />
              <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'>success</span></h2>
          </div>
          <div className='text-white'>
            <Button />
          </div>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-4 mt-12'>
          <div>
            <p className='text-base font-medium leading-6 md:mb-8 sm:mb-6 mb-4'>Mid-Atlantic Waste Systems would not exist without creating value for our customers through top-quality products combined with knowledgeable and committed employees. MAWS continues to find new and innovative ways to stay on top of current trends in the waste industry so as to bring fresh ideas to our customers. We appreciate the confidence and trust our customers have given to us over the past 38 years and will work hard to maintain that trust over the next 38 years.</p>
            <p className='text-base font-medium leading-6'>In addition to our extensive inventory, our commitment goes beyond merely providing equipment. We understand the critical importance of keeping your operations running smoothly, and that’s why we offer top-notch parts and services. Whether you need routine maintenance, repairs, or replacement parts, our team is here to ensure that your heavy equipment functions at peak performance.</p>
          </div>
          <div>
            <p className='text-red font-extrabold text-xl leading-6 mb-5'>THE BEGINNING</p>
            <p className='text-base font-medium leading-6 md:mb-8 sm:mb-6 mb-4'>Founded in 1985 by Rich Hood, Mid-Atlantic Waste Systems began in a modest office in Centreville, Maryland. Together with his wife, who was his first office manager and bookkeeper, Rich moved their office to Easton, Maryland in 1988, which subsequently became the Corporate headquarters location. In 1993, the Company opened an additional facility in Clinton, MD and continued a pattern of growth from that point on.</p>
            <p className='text-base font-medium leading-6 md:mb-8 sm:mb-6 mb-4'>Today, Mid-Atlantic Waste serves customers with (8) eight fully staffed service locations in the Mid-Atlantic region.</p>
            <div class="w-24 font-sans flex items-center gap-6">
              <img src={RichHood} alt="Logo" class="w-28 aspect-square p-1 border border-blackText rounded-full mb-2" />
              <div className='w-full min-w-max'>
                <div class="text-sm font-medium mb-2 text-blackText">RICH HOOD</div>
                <div class="text-xs font-medium text-blackText">FOUNDER</div>
              </div>
                <img src={Signature} alt='Signature' />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Sliders />
    </>
  )
}

export default History;