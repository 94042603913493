import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button/Button';

import ProductsImage from '../assets/image/Products-Image.png';
import CraneTruck from '../assets/image/CraneTruck.svg';
import Union from '../assets/image/Union.svg';
import Parts from '../assets/image/Parts.svg';
import RightIcon from '../assets/image/RightIcon.svg';
import Truck from '../assets/image/Truck.png';
import Sliders from '../components/Slider/Sliders';

function Home() {
    return (
        <>
            <section id='banner' className='bg-banner-image bg-cover bg-center bg-no-repeat md:py-28 sm:py-20 py-10 relative'>
                <span className='bg-[#00000059] absolute top-0 right-0 w-full h-full'></span>
                <div className='container mx-auto relative z-10'>
                    <div className='text-white'>
                        <p className='md:text-2xl text-xl font-medium md:mb-7 mb-5 drop-shadow-3xl uppercase'>Mid-Atlantic Waste Systems</p>
                        <h1 className='md:text-5xl text-3xl font-extrabold md:mb-11 mb-9 drop-shadow-3xl uppercase'>Your Single Source Provider <br /> of Heavy Equipment & Fleet <br /> Maintenance</h1>
                        <Button />
                    </div>
                </div>
            </section>
            <section className='md:py-28 sm:py-20 py-10'>
                <div className='container mx-auto'>
                    <h2 className='uppercase md:leading-[70px] leading-[normal] text-blue md:text-[50px] sm:text-[40px] text-[30px] font-medium text-center'>WE  
                        <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'> SELL</span> THE BEST <br /> AND 
                        <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'> REPAIR</span> THE REST</h2>
                    <div className='md:mt-10 sm:mt-8 mt-4 md:w-[80%] mx-auto w-full text-blackText'>
                        <p className='text-base font-medium text-center leading-6 md:mb-8 sm:mb-6 mb-4'>We have 8 locations to serve you! With over 35 years of experience, industry knowledge, and commitment to customer service, Mid-Atlantic Waste Systems stands as your go-to solutions specialist provider for heavy equipment and heavy truck sales, service, and parts.</p>
                        <p className='text-base font-medium text-center leading-6'>Mid-Atlantic Waste Systems specializes in a wide range of heavy equipment, our company is dedicated to meeting your diverse needs. Heavy equipment from refuse trucks, solid waste equipment, compactors and balers, and sewer equipment to knuckleboom trucks, service cranes, material handling equipment, and sweepers, we provide the equipment, as well as the parts and service, to keep you up and running!</p>
                    </div>
                </div>
            </section>
            <section className='md:pt-24 sm:pt-20 pt-10 mb-24 bg-blackBg'>
                <div className='container mx-auto'>
                    <h2 className='uppercase text-red md:text-[50px] sm:text-[40px] text-[30px] md:leading-[70px] leading-[normal] font-medium text-center'>
                        <span className='outline-text-white tracking-[10%] font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'>EXPLORE </span>
                         OUR PRODUCTS & 
                        <span className='outline-text-white tracking-[10%] font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'> SERVICES</span>
                    </h2>
                    <div className='grid relative bottom-[-80px] lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-8 gap-4 md:mt-[6px] mt-[0px]'>
                        <CardSection backImage={ProductsImage} isonImage={CraneTruck} buttonName="Equipement" />
                        <CardSection backImage={ProductsImage} isonImage={Union} buttonName="Fleet Services" />
                        <CardSection backImage={ProductsImage} isonImage={Parts} buttonName="Parts" />
                    </div>
                </div>
            </section>
            <section className='md:py-28 sm:py-20 py-10'>
                <div className='container mx-auto'>
                    <h2 className='uppercase text-red md:text-[50px] sm:text-[40px] text-[30px] md:leading-[70px] leading-[normal] font-medium text-center'>
                        <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'>INDUSTRY </span>
                        PROUDLY <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] uppercase'>SERVING</span></h2>
                    <div className='md:w-4/5 w-full mx-auto grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-12'>
                        <Proudly name="Easton MD" />
                        <Proudly name="Clinton MD" />
                        <Proudly name="Salem VA" />
                        <Proudly name="Salem VA" />
                        <Proudly name="Chesapeake VA" />
                        <Proudly name="Hampton VA" />
                        <Proudly name="Cheswick PA" />
                        <Proudly name="New Castle DE" />
                    </div>
                </div>
            </section>
            <Sliders />
            <section className='md:py-28 sm:py-20 py-10'>
                <div className='container mx-auto'>
                    <div className='flex md:flex-nowrap flex-wrap gap-4 justify-between items-end'>
                        <div>
                            <p className='text-red font-extrabold text-xl leading-6 mb-5 uppercase'>Media</p>
                            <h2 className='uppercase md:leading-[70px] leading-[normal] text-blue md:text-[50px] sm:text-[40px] text-[30px] font-medium'>Recent news <br />
                            <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'>& events</span></h2>
                        </div>
                        <div className='text-white'>
                            <Button />
                        </div>
                    </div>
                    <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-12'>
                        <ProductsCard />
                        <ProductsCard />
                        <ProductsCard />
                    </div>
                </div>
            </section>
        </>
    )
}

function Proudly(props) {
    return (
        <>
            <div className='flex justify-start items-center gap-4'>
                <img className='w-6 aspect-square' src={RightIcon} alt='RightIcon' />
                <span className='text-base font-bold leading-6 text-blackText'>{props.name}</span>
            </div>
        </>
    )
}

function ProductsCard() {
    return (
        <>
            <div className="rounded overflow-hidden shadow-lg">
                <div className="relative">
                    <img className="w-full" src={Truck} alt="MOUNTAINEER" />
                    <div className="flex justify-start items-center gap-5 pr-5 rounded overflow-hidden font-normal text-sm mb-2 absolute top-5 left-5 text-blackText bg-white"><span className='py-2 px-4 text-white bg-red font-black text-xl'>05</span> June</div>
                </div>
                <div className="px-5 mt-5"> 
                    <div className="font-bold text-xl mb-2">LOOKING BACK AND MOVING FORWARD</div>
                    <p className="text-gray-700 text-base">
                        I want to express my appreciation to your team for their support for a Mexico shipment...
                    </p>
                </div>
                <div className="px-5 mt-4 mb-6">
                    <button className="text-red group flex items-center gap-1 font-bold text-base">
                        <svg className='group-hover:rotate-[45deg] transition' xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.01662 14.8351C6.85428 15.0493 6.87294 15.3581 7.0711 15.5563C7.28728 15.7725 7.6352 15.775 7.84821 15.562L14.6278 8.78239L14.6542 13.7069L14.6606 13.7881C14.7012 14.0535 14.9307 14.2589 15.2066 14.2615C15.5101 14.2644 15.7548 14.0207 15.7532 13.7172L15.7202 7.47262C15.7207 7.43614 15.7176 7.39957 15.7109 7.36349C15.6631 7.10551 15.4363 6.9083 15.1654 6.90689L8.91023 6.8743L8.82909 6.87984C8.56449 6.9177 8.36334 7.14505 8.36597 7.42095C8.36886 7.72443 8.61721 7.97174 8.92069 7.97332L13.8456 7.99895L7.06538 14.7792L7.01662 14.8351Z" fill="#EB0602"/>
                        </svg>
                        READ MORE
                    </button>
                </div>
            </div>
        </>
    )
}

function CardSection(props) {
    return (
        <>
            <div className='rounded-[10px] overflow-hidden relative'>
                <img className='w-full h-full aspect-[7/8] object-bottom object-cover' src={props.backImage} alt='ProductsImage' />
                <span className='bg-custom-gradient absolute top-0 right-0 w-full h-full'></span>
                <div className='flex justify-between items-stretch absolute bottom-0 right-0 w-full'>
                    <img className='p-[15px] h-[80px] aspect-square' src={props.isonImage} alt='CraneTruck' />
                    <Link className='flex items-center justify-center text-[25px] text-white group gap-[10px] bg-gradient-to-b from-red to-redLite py-3 px-5 w-full rounded-tl-[10px] rounded-br-[10px] transition
            hover:bg-gradient-to-b hover:from-redLite hover:to-red' to='/'>{props.buttonName}</Link>
                </div>
            </div>
        </>
    )
}
export default Home;