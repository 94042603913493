import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function MAWSRoadmap() {
  const links = ['Home', 'Resources'];
  return (
    <>
      <BannerInner pageName="MAWS ROADMAP" links={links} />
      <Sliders />
    </>
  )
}

export default MAWSRoadmap;