import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function Branches() {
  const links = ['Home', 'Resources'];
  return (
    <>
    <BannerInner pageName="Branches" links={links} />
    <Sliders />
    </>
  )
}

export default Branches;