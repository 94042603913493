import React, { useEffect, useState } from 'react';
import Logo from '../../assets/image/MAT&E Logo.svg';
import Search from '../../assets/image/Search.svg';
import Dropdown from '../../assets/image/Dropdown.svg';

import Facebook from '../../assets/image/Facebook.svg';
import Twitter from '../../assets/image/Twitter.svg';
import Instagram from '../../assets/image/Instagram.svg';
import LinkedIn from '../../assets/image/LinkedIn.svg';
import YouTube from '../../assets/image/YouTube.svg';
import Mail from '../../assets/image/Mail.svg';
import Call from '../../assets/image/Call.svg';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
    const location = useLocation();
    const { pathname } = location;

    // Toggle the specific dropdown
    const toggleDropdown = (name) => {
        if (openDropdown === name) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(name);
        }
    };

    // Close the dropdown when clicking outside
    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown')) {
            setOpenDropdown(null);
        }
    };

    // Listen for clicks outside the dropdown to close it
    useEffect(() => {
        if (openDropdown !== null) {
            document.addEventListener('click', closeDropdown);
        } else {
            document.removeEventListener('click', closeDropdown);
        }

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [openDropdown]);
    return (
        <div className='sticky top-0 z-50'>
            <header className='bg-blackBg text-white py-2'>
                <div className='container mx-auto'>
                    <div className='flex justify-between items-center'>
                        <ul className='flex gap-4 items-center'>
                            <li><Link to=''><img src={Facebook} alt='Facebook' /></Link></li>
                            <li><Link to=''><img src={Twitter} alt='Twitter' /></Link></li>
                            <li><Link to=''><img src={Instagram} alt='Instagram' /></Link></li>
                            <li><Link to=''><img src={LinkedIn} alt='LinkedIn' /></Link></li>
                            <li><Link to=''><img src={YouTube} alt='YouTube' /></Link></li>
                        </ul>
                        <ul className='flex gap-6 items-center'>
                            <li><Link className='flex gap-2 items-center' to=''><img src={Mail} alt='Mail' /><span className='md:block hidden'>unreal@outlook.com</span></Link></li>
                            <li><Link className='flex gap-2 items-center' to=''><img src={Call} alt='Call' /><span className='md:block hidden'>800-338-7274</span></Link></li>
                        </ul>
                    </div>
                </div>
            </header>

            <header className="shadow-md bg-white">
                <nav className="container mx-auto py-4 flex justify-between items-center relative">

                    {/* Logo */}
                    <div className="flex items-center justify-between md:w-auto w-full">
                        <Link to="/" className="logo">
                            <img src={Logo} alt="logo" />
                        </Link>
                        <button
                            id="nav-icon"
                            className={`md:hidden ml-4 bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded ${isNavOpen ? 'toggle-open' : ''}`}
                            onClick={() => setIsNavOpen(!isNavOpen)}
                        >
                            {/* Hamburger Icon */}
                                <span></span>
                                <span></span>
                                <span></span>
                        </button>
                    </div>

                    {/* Menu Links */}
                    <div className={`menu-links ${isNavOpen ? 'show-menu' : 'hide-menu'} md:flex md:items-center`}>
                        <ul className="header-menu flex flex-col md:flex-row lg:gap-8 md:gap-4 gap-4  md:items-center items-start">
                            <li>
                                <Link
                                    to="/"
                                    className={`${pathname === '/' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(false)}
                                >
                                    Home
                                </Link>
                            </li>

                            {/* About Us Dropdown */}
                            <li className="dropdown relative">
                                <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => toggleDropdown('about')}
                                >
                                    <span
                                        className={`flex items-center ${pathname.startsWith('/about') ? 'active-menu' : ''}`}
                                    >
                                        About Us
                                        <img
                                            className={`ml-1 dropdown-arrow transition-transform transform ${openDropdown === 'about' ? 'rotate-180' : 'rotate-0'}`}
                                            src={Dropdown}
                                            alt="Dropdown"
                                        />
                                    </span>
                                </div>
                                {openDropdown === 'about' && (
                                    <div className="dropdown-menu bg-white shadow-lg p-4 mt-2 w-48 z-10">
                                        <ul>
                                            <li className="nav-link">
                                                <Link
                                                    to="/history"
                                                    className={pathname === '/history' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    History
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/mid-atlantic-way"
                                                    className={pathname === '/mid-atlantic-way' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Mid-Atlantic Way
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            {/* Our Services Dropdown */}
                            <li className="dropdown relative">
                                <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => toggleDropdown('services')}
                                >
                                    <span
                                        className={`flex items-center ${pathname.startsWith('/services') ? 'active-menu' : ''}`}
                                    >
                                        Our Services
                                        <img
                                            className={`ml-1 dropdown-arrow transition-transform transform ${openDropdown === 'services' ? 'rotate-180' : 'rotate-0'}`}
                                            src={Dropdown}
                                            alt="Dropdown"
                                        />
                                    </span>
                                </div>
                                {openDropdown === 'services' && (
                                    <div className="dropdown-menu bg-white shadow-lg p-4 mt-2 w-48 z-10">
                                        <ul>
                                            <li className="nav-link">
                                                <Link
                                                    to="/fleet-services"
                                                    className={pathname === '/fleet-services' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Fleet Services
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/parts"
                                                    className={pathname === '/parts' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Parts
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            {/* Sales Dropdown */}
                            <li className="dropdown relative">
                                <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => toggleDropdown('sales')}
                                >
                                    <span
                                        className={`flex items-center ${pathname.startsWith('/sales') ? 'active-menu' : ''}`}
                                    >
                                        Sales
                                        <img
                                            className={`ml-1 dropdown-arrow transition-transform transform ${openDropdown === 'sales' ? 'rotate-180' : 'rotate-0'}`}
                                            src={Dropdown}
                                            alt="Dropdown"
                                        />
                                    </span>
                                </div>
                                {openDropdown === 'sales' && (
                                    <div className="dropdown-menu bg-white shadow-lg p-4 mt-2 w-48 z-10">
                                        <ul>
                                            <li className="nav-link">
                                                <Link
                                                    to="/in-stock"
                                                    className={pathname === '/in-stock' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    In Stock
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/inventory"
                                                    className={pathname === '/inventory' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Inventory
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            {/* Resources Dropdown */}
                            <li className="dropdown relative">
                                <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => toggleDropdown('resources')}
                                >
                                    <span
                                        className={`flex items-center ${pathname.startsWith('/resources') ? 'active-menu' : ''}`}
                                    >
                                        Resources
                                        <img
                                            className={`ml-1 dropdown-arrow transition-transform transform ${openDropdown === 'resources' ? 'rotate-180' : 'rotate-0'}`}
                                            src={Dropdown}
                                            alt="Dropdown"
                                        />
                                    </span>
                                </div>
                                {openDropdown === 'resources' && (
                                    <div className="dropdown-menu bg-white shadow-lg p-4 mt-2 w-48 z-10">
                                        <ul>
                                            <li className="nav-link">
                                                <Link
                                                    to="/our-team"
                                                    className={pathname === '/our-team' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Our Team
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/branches"
                                                    className={pathname === '/branches' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Branches
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/find-sales-representative"
                                                    className={pathname === '/find-sales-representative' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    Find Sales Representative
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/MAWS-roadmap"
                                                    className={pathname === '/MAWS-roadmap' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    MAWS Roadmap
                                                </Link>
                                            </li>
                                            <li className="nav-link">
                                                <Link
                                                    to="/news-events"
                                                    className={pathname === '/news-events' ? 'active-menu' : ''}
                                                    onClick={() => {
                                                        setIsNavOpen(false);
                                                        setOpenDropdown(null);
                                                    }}
                                                >
                                                    News & Events
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            {/* Contact Us Link */}
                            <li>
                                <Link
                                    to="/contact-us"
                                    className={`${pathname === '/contact-us' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(false)}
                                >
                                    Contact Us
                                </Link>
                            </li>

                            {/* Search Icon */}
                            <li>
                                <Link to="/">
                                    <img src={Search} alt="Search" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header;