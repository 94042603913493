import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Sliders() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    return (
        <section id='testimonials' className='md:py-16 max-w-full overflow-hidden'>
            <div className='container mx-auto'>
                <p className='text-red font-extrabold text-xl leading-6 mb-5'>TESTIMONIALS</p>
                <h2 className='uppercase md:leading-[70px] leading-[normal] text-blue md:text-[50px] sm:text-[40px] text-[30px] font-medium'>What our happy <br /><span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal] uppercase'>clients say</span></h2>
                <div className='slider'>
                    <ul>
                        <Slider {...settings}>
                            <li className='md:px-8 px-4'>
                                <Slid 
                                name='James G. Leone 01' designation='Margot E. Peeples'
                                image='https://randomuser.me/api/portraits/women/68.jpg' 
                                imageAlt='women' 
                                details='As a novice Amazon Seller, I hired TrackTruck to organize shipping from China to Dallas.' 
                                 />
                            </li>
                            <li className='md:px-8 px-4'>
                                <Slid 
                                name='Linda M. Thompso' designation='Margot E. Peeples'
                                image='https://randomuser.me/api/portraits/women/68.jpg' 
                                imageAlt='women' 
                                details='I want to express my appreciation to your team for their support for a Mexico shipment for a list of machines and fixtures that were delivered today.I want to express my appreciation to your team for.' 
                                 />
                            </li>
                            <li className='md:px-8 px-4'>
                                <Slid 
                                name='erry C. Bennett' designation='Margot E. Peeples'
                                image='https://randomuser.me/api/portraits/women/68.jpg' 
                                imageAlt='women' 
                                details='As a novice Amazon Seller, I hired TrackTruck to organize shipping from China to Dallas.' 
                                 />
                            </li>
                            <li className='md:px-8 px-4'>
                                <Slid 
                                name='Linda M. Thompso' designation='Margot E. Peeples'
                                image='https://randomuser.me/api/portraits/women/68.jpg' 
                                imageAlt='women' 
                                details='I want to express my appreciation to your team for their support for a Mexico shipment for a list of machines and fixtures that were delivered today.I want to express my appreciation to your team for.' 
                                 />
                            </li>
                        </Slider>
                    </ul>
                </div>
            </div>
        </section>
    )
}

function Slid(props) {
    return (
        <div className="bg-white border border-[#DFDFDF] rounded-xl shadow-[0px_4px_30px_0px_#0000001A] p-8 max-w-full my-16">
            <div className="text-red-500 text-3xl mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="40" viewBox="0 0 35 40" fill="none">
                    <path d="M0.5 0.5H15.4095V26.3945L0.5 38.9266V0.5Z" stroke="#EB0602"/>
                    <path d="M19.5898 0.5H34.4993V26.3945L19.5898 38.9266V0.5Z" stroke="#EB0602"/>
                </svg>
            </div>
            <p className="text-gray-800 text-lg mb-6">
                {props.details}
            </p>
            <div className="flex items-center">
                <img className="w-12 h-12 rounded-full border-2 border-gray-200 mr-4" src={props.image} alt={props.imageAlt} />
                <div>
                    <h3 className="text-gray-900 font-semibold text-base">{props.name}</h3>
                    <p className="text-gray-500 text-sm">{props.designation}</p>
                </div>
            </div>
        </div>
    )
}
export default Sliders;