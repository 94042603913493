import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Parts from './pages/Parts';
import InStock from './pages/InStock';
import Inventory from './pages/Inventory';
import OurTeam from './pages/OurTeam';
import Branches from './pages/Branches';
import FindSalesRepresentative from './pages/FindSalesRepresentative';
import MAWSRoadmap from './pages/MAWSRoadmap';
import NewsEvents from './pages/NewsEvents';
import ContactUs from './pages/ContactUs';
import MidAtlanticWay from './pages/MidAtlanticWay';
import FleetServices from './pages/FleetServices';
import History from './pages/History';
import ClientsPartners from './components/ClientsPartners/ClientsPartners';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/history" element={<History />} />
          <Route path="/mid-atlantic-way" element={<MidAtlanticWay />} />
          <Route path="/fleet-services" element={<FleetServices />} />
          <Route path="/parts" element={<Parts />} />
          <Route path="/in-stock" element={<InStock />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/branches" element={<Branches />} />
          <Route path="/find-sales-representative" element={<FindSalesRepresentative />} />
          <Route path="/MAWS-roadmap" element={<MAWSRoadmap />} />
          <Route path="/news-events" element={<NewsEvents />} />
        </Routes>
        <ClientsPartners />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
