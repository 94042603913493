import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function Parts() {
  const links = ['Home', 'Our Services'];
  return (
    <>
    <BannerInner pageName="PARTS" links={links} />
    <Sliders />
    </>
  )
}

export default Parts;