import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function Inventory() {
  const links = ['Home', 'Sales'];
  return (
    <>
    <BannerInner pageName="Inventory" links={links} />
    <Sliders />
    </>
  )
}

export default Inventory;