import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function FleetServices() {
  const links = ['Home', 'Our Services'];
  return (
    <>
    <BannerInner pageName="Fleet Services" links={links} />
    <Sliders />
    </>
  )
}

export default FleetServices;