import React from 'react'
import BannerInner from '../components/BannerInner/BannerInner';
import Sliders from '../components/Slider/Sliders';

function InStock() {
  const links = ['Home', 'Sales'];
  return (
    <>
    <BannerInner pageName="In-Stock" links={links} />
    <Sliders />
    </>
  )
}

export default InStock;