import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image56 from '../../assets/image/image 56.png'
import Image50 from '../../assets/image/image 50.png'
import Image51 from '../../assets/image/image 51.png'
import Image53 from '../../assets/image/image 53.png'
import Image54 from '../../assets/image/image 54.png'
import Image55 from '../../assets/image/image 55.png'
import Button from '../Button/Button';


function ClientsPartners() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
        <section id='testimonials' className='bg-gray py-16'>
            <div className='container mx-auto'>
                <div className='grid sm:grid-cols-2 grid-cols-1 items-end gap-4'>
                    <h2 className='uppercase md:leading-[70px] leading-[normal] text-blue md:text-[50px] sm:text-[40px] text-[30px] font-medium'>Clients <br /> & <span className='outline-text-black font-black md:text-[45px] sm:text-[35px] text-[25px] md:leading-[63px] leading-[normal]'>partners</span></h2>
                    <p>Track//Truck is in the small-business business. Built on solid, long-term relationships, the company enables thousands of independent transportation providers to operate their own businesses as members of the Track//Truck network.</p>
                </div>
                <div className='slider overflow-hidden mt-20'>
                    <ul>
                        <Slider {...settings}>
                            <li className='px-8'>
                                <Slid img={Image56} alt='Image56' />
                            </li>
                            <li className='px-8'>
                                <Slid img={Image50} alt='Image50' />
                            </li>
                            <li className='px-8'>
                                <Slid img={Image51} alt='Image51' />
                            </li>
                            <li className='px-8'>
                                <Slid img={Image53} alt='Image53' />
                            </li>
                            <li className='px-8'>
                                <Slid img={Image54} alt='Image54' />
                            </li>
                            <li className='px-8'>
                                <Slid img={Image55} alt='Image55' />
                            </li>
                        </Slider>
                    </ul>
                </div>
            </div>
        </section>
        <section className='bg-blue md:py-14 py-7'>
            <div className='container mx-auto'>
                <div className='text-white flex md:flex-nowrap flex-wrap justify-center items-center md:gap-8 gap-4'>
                    <p className='text-2xl font-normal leading-[44px]'>Get In Touch With Us</p>
                    <Button />
                </div>
            </div>
        </section>
        </>
    )
}

function Slid(props) {
    return (
        <>
            <div>
                <img className='h-[80px] aspect-video object-contain object-center' src={props.img} alt={props.alt} />
            </div>
        </>
    )
}

export default ClientsPartners;