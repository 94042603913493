import React from 'react';
import FooterLogo from '../../assets/image/FooterLogo.svg';
import Location from '../../assets/image/Location.svg';
import { Link } from 'react-router-dom';

import Facebook from '../../assets/image/Facebook.svg';
import Twitter from '../../assets/image/Twitter.svg';
import Instagram from '../../assets/image/Instagram.svg';
import LinkedIn from '../../assets/image/LinkedIn.svg';
import YouTube from '../../assets/image/YouTube.svg';
import FooterMail from '../../assets/image/FooterMail.svg';

function Footer() {
    return (
        <footer className='bg-blackBg md:pt-28 sm:pt-20 pt-10 pb-10'>
            <div className='container mx-auto'>
                <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 sm:pb-20 pb-10'>
                    <div className='md:col-span-1 sm:col-span-2 col-span-1'>
                        <img src={FooterLogo} alt='FooterLogo' />
                    </div>
                    <div className='flex md:justify-center justify-start items-start md:gap-6 gap-2 md:w-auto w-full'>
                        <img src={Location} alt='Location' />
                        <div className='text-white'>
                            <p className='text-xl font-bold mb-5'>Our Location</p>
                            <p className='text-base font-normal'>CORPORATE HEADQUARTERS <br/> 10641 CORDOVA ROAD</p>
                        </div>
                    </div>
                    <div className='flex sm:justify-end justify-start items-start md:gap-6 gap-2 md:w-auto w-full'>
                        <img src={FooterMail} alt='Location' />
                        <div className='text-white'>
                            <p className='text-xl font-bold mb-5'>How can we help?</p>
                            <p className='text-base font-normal'>
                                <Link className='block text-base font-normal mb-1'>123-456-78901</Link>
                                <Link className='block text-base font-normal mb-1'>321-123-45678</Link>
                                <Link className='block text-base font-normal'>Info@support.com</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mx-auto border-t border-grayBorder'>
                <div className='flex md:flex-nowrap flex-wrap gap-4 justify-between items-center text-white pt-10'>
                    <ul className='flex gap-6 items-center'>
                        <li><Link className='flex gap-2 items-center' to=''>© 2024 Mid-Atlantic Waste Systems</Link></li>
                    </ul>
                    <ul className='flex gap-4 items-center'>
                        <li><Link to=''><img src={Facebook} alt='Facebook' /></Link></li>
                        <li><Link to=''><img src={Twitter} alt='Twitter' /></Link></li>
                        <li><Link to=''><img src={Instagram} alt='Instagram' /></Link></li>
                        <li><Link to=''><img src={LinkedIn} alt='LinkedIn' /></Link></li>
                        <li><Link to=''><img src={YouTube} alt='YouTube' /></Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;