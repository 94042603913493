import React from 'react'
import { Link } from 'react-router-dom';
import ButtonArrow from '../../assets/image/Button-Arrow.svg'

function Button() {
    return (
        <>
            <Link className='flex group gap-[10px] uppercase bg-gradient-to-b from-red to-redLite py-3 px-5 w-fit rounded-md transition
            hover:bg-gradient-to-b hover:from-redLite hover:to-red' href='https://tailwindcss.com/' >
                <img className='group-hover:rotate-45 transition' src={ButtonArrow} alt='ButtonArrow' /> Contact us</Link>
        </>
    )
}

export default Button;