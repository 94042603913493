import React from 'react'
import Sliders from '../components/Slider/Sliders';
import BannerInner from '../components/BannerInner/BannerInner';

function FindSalesRepresentative() {
  const links = ['Home', 'Resources'];
  return (
    <>
      <BannerInner pageName="FIND SALES REPRESENTATIVE" links={links} />
      <Sliders />
    </>
  )
}

export default FindSalesRepresentative;