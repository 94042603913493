import React from 'react';
import TruckBg from '../../assets/image/TruckBg.png';
import { Link } from 'react-router-dom';

function BannerInner({ links, pageName, linkUrl }) {
    return (
        <>
            <section className='bg-inner-banner bg-cover bg-center bg-no-repeat relative'>
                <div className='container mx-auto'>
                    <div className='py-32 min-h-[420px] text-white'>
                        <p className='uppercase text-5xl font-extrabold mb-7'>{pageName}</p>
                        <ul id='innerBanner' className='flex justify-start items-center gap-2'>
                            {links.map((link, index) => (
                                <>
                                    <li className='uppercase text-sm font-medium text-white hover:text-red transition' key={index}>
                                        <Link to={linkUrl}>{link}</Link>
                                    </li>
                                    <span className='w-[6px] h-[6px] bg-red rounded-full block'></span>
                                </>
                            ))}
                        </ul>
                    </div>
                </div>
                <img className='w-[600px] absolute mr-0 ml-auto right-0 -bottom-5' src={TruckBg} alt='TruckBg' />
            </section>
        </>
    )
}

export default BannerInner;